<template>
  <div>
    <ModalEditYieldPrice
      v-if="showModalEditPrice"
      :showModal="showModalEditPrice"
      :onClickCancel="closeEditModal"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="setShowEditPrice"
      :editLabel="'Edit'"
    >
      <template v-slot:title>Base price</template>
    </ui-section-header>

    <message
      v-if="yieldSettingsState.yieldSettings.PriceParts.length === 0"
      :message="'No price settings found'"
      :type="'is-warning'"
    />

    <table class="table is-fullwidth">
      <tbody>
        <tr v-if="yieldSettingsState.yieldSettings.VoucherId === 0">
          <td width="50%">{{ $t('Prices.IsActiveFrom') }}</td>
          <td>
            {{
              yieldSettingsState.yieldSettings.ValidFrom
                | longDateFormat($i18n.locale)
            }}
          </td>
        </tr>
        <tr>
          <td width="50%">{{ $t('Prices.Status') }}</td>
          <td>
            <span
              v-if="yieldSettingsState.yieldSettings.Status === 'Approved'"
              class="tag is-success"
              >{{ $t('PriceStatus.Approved') }}</span
            >
            <span
              v-if="yieldSettingsState.yieldSettings.Status === 'Draft'"
              class="tag is-warning"
              >{{ $t('PriceStatus.Draft') }}</span
            >
            <span
              v-if="yieldSettingsState.yieldSettings.Status === 'Pending'"
              class="tag is-light"
              >{{ $t('PriceStatus.Pending') }}</span
            >
            <span
              v-if="yieldSettingsState.yieldSettings.Status === 'Denied'"
              class="tag is-danger"
              >{{ $t('PriceStatus.Denied') }}</span
            >
          </td>
        </tr>
        <tr>
          <td>{{ $t('Prices.VatRate') }}</td>
          <td>{{ yieldSettingsState.yieldSettings.TaxPercentage }}%</td>
        </tr>
        <tr>
          <td>{{ $t('Prices.CalculationType') }}</td>
          <td>
            {{
              getCalculationTypeName(
                yieldSettingsState.yieldSettings.CalculationType
              )
            }}
          </td>
        </tr>
        <tr>
          <td>{{ $t('Prices.HoursPerDayPart') }}</td>
          <td>
            {{ yieldSettingsState.yieldSettings.HoursPerDayPart }}
          </td>
        </tr>

        <tr>
          <td>{{ $t('Prices.SpacePriceCapMin') }}</td>
          <td>
            <span
              v-if="yieldSettingsState.yieldSettings.SpacePriceCapMin === 0"
              >{{ $t('Prices.InActive') }}</span
            >
            <span
              v-if="yieldSettingsState.yieldSettings.SpacePriceCapMin > 0"
              >{{
                yieldSettingsState.yieldSettings.SpacePriceCapMin
                  | toCurrency(
                    locationState.Settings.LanguageCulture,
                    locationState.CurrencyIso
                  )
              }}</span
            >
          </td>
        </tr>

        <tr>
          <td>{{ $t('Prices.SpacePriceCapMax') }}</td>
          <td>
            <span
              v-if="yieldSettingsState.yieldSettings.SpacePriceCapMax === 0"
              >{{ $t('Prices.InActive') }}</span
            >
            <span
              v-if="yieldSettingsState.yieldSettings.SpacePriceCapMax > 0"
              >{{
                yieldSettingsState.yieldSettings.SpacePriceCapMax
                  | toCurrency(
                    locationState.Settings.LanguageCulture,
                    locationState.CurrencyIso
                  )
              }}</span
            >
          </td>
        </tr>

        <tr>
          <td>{{ $t('Prices.PriceBuildUp') }}</td>
          <td>
            <Message
              v-if="yieldSettingsState.yieldSettings.PriceParts.length === 0"
              :message="$t(`Prices.Message_NoPriceFound`)"
              type="is-warning"
            />

            <table
              v-if="yieldSettingsState.yieldSettings.PriceParts.length > 0"
              class="table is-fullwidth is-narrow is-striped"
            >
              <thead>
                <tr>
                  <th>{{ $t('Prices.DayPart') }}</th>
                  <th class="has-text-right">{{ $t('Prices.Price') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(pricePart, index) in yieldSettingsState.yieldSettings
                    .PriceParts"
                  :key="index"
                >
                  <td>
                    <span
                      v-if="
                        yieldSettingsState.yieldSettings.CalculationType === 0
                      "
                      >{{ $t('Prices.Hour') }}
                    </span>
                    <span>{{ pricePart.Part }}</span>
                    <span
                      v-if="
                        yieldSettingsState.yieldSettings.CalculationType ===
                          1 && pricePart.Part === 1
                      "
                      >{{ $t('Prices.DayPart') }}</span
                    >
                    <span
                      v-if="
                        yieldSettingsState.yieldSettings.CalculationType ===
                          1 && pricePart.Part > 1
                      "
                      >{{ $t('Prices.DayParts') }}</span
                    >
                  </td>
                  <td width="100" class="has-text-right">
                    {{ pricePart.Price | toCurrency('nl-NL', 'EUR') }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const Message = () => import('@/components/UI/Message')
const ModalEditYieldPrice = () =>
  import('@/components/Yield/ModalEditYieldPrice')

export default {
  components: {
    message: Message,
    ModalEditYieldPrice,
  },

  data() {
    return {
      priceCalculationTypes: [],
      showModalEditPrice: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('yieldStore', ['yieldSettingsState']),
  },

  created() {
    this.priceCalculationTypes.push({
      id: 0,
      type: 'PerHourPerSeat',
      name: 'Price per seat per hour',
    })
    this.priceCalculationTypes.push({
      id: 1,
      type: 'PerDayPartOnly',
      name: 'Price per day part',
    })
    this.priceCalculationTypes.push({
      id: 2,
      type: 'PerDayPartPerPerson',
      name: 'Price per day part per person',
    })
  },

  methods: {
    getCalculationTypeName(type) {
      let calcIndex = this.priceCalculationTypes.findIndex(
        (pc) => pc.type === type
      )
      return this.priceCalculationTypes[calcIndex].name
    },

    setShowEditPrice() {
      this.showModalEditPrice = true
    },

    closeEditModal() {
      this.showModalEditPrice = false
    },
  },
}
</script>
